// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contain-btn {
  padding: 0px 0px;
  /* background-color: red; */
  min-width: 400px;
}

.main-btn {
  border: none;
  padding: 6px 30px;
  background: #c7c6c6;
}
.option-btn {
  background-color: #c7c6c6;
  color: white;
  font-weight: bold;
  padding: 6px 40px;
  margin: 0px 20px;
}
.option-cancle-btn {
  /* background-color: red; */
  height: 20px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.float-option-btns {
  position: absolute;
  /* top: -20px; */
}
.color-grey {
  color: rgb(119, 119, 119);
}
.margin-left-50 {
  margin-left: 50px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChoiceButton/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,2BAA2B;EAC3B,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".contain-btn {\n  padding: 0px 0px;\n  /* background-color: red; */\n  min-width: 400px;\n}\n\n.main-btn {\n  border: none;\n  padding: 6px 30px;\n  background: #c7c6c6;\n}\n.option-btn {\n  background-color: #c7c6c6;\n  color: white;\n  font-weight: bold;\n  padding: 6px 40px;\n  margin: 0px 20px;\n}\n.option-cancle-btn {\n  /* background-color: red; */\n  height: 20px;\n  aspect-ratio: 1/1;\n  border-radius: 50%;\n}\n.float-option-btns {\n  position: absolute;\n  /* top: -20px; */\n}\n.color-grey {\n  color: rgb(119, 119, 119);\n}\n.margin-left-50 {\n  margin-left: 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
