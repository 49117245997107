import { MdEmail, MdPhone, MdWeb } from "react-icons/md";
import "./style.css";
import moment from "moment";
import { IoMdGlobe } from "react-icons/io";

export default function SlipPrintLayout(props) {
  return (
    <div className="slip-container relative rotate-90 ">
      <div className="slip-logo space-between">
        <span className="logo-img">
          <img src="https://raysflorist.work/static/media/logo.dafab82a719093665bde.png" />
        </span>
        <span
          className="no-wrap bold-1"
          style={{
            display: "flex",
            textAlign: "left",
            justifyContent: "start",
            alignItems: "center",
            marginRight: "20px",
            fontSize: "16px",
          }}
        >
          Ray's Florist & Gifts
        </span>
        <span
          className="no-wrap"
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
            fontSize: "16px",
          }}
        >
          Making Funerals Beautiful
        </span>
      </div>
      <div className="row ">
        <div className="col-md-12">
          <div className="slip-info space-between">
            <span className="name no-wrap">Funeral Name</span>
            <span className="text">{props.details.late_person_name}</span>
          </div>
          <div className="slip-info space-between">
            <span className="name no-wrap">Funeral Location </span>
            <span className="text">
              {JSON.parse(props.details.funeral_location || "{}").name}
            </span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="slip-info space-between">
            <span className="name no-wrap">Funeral Director </span>
            <span className="text">
              {JSON.parse(props.details.fd_code || "{}").code}
            </span>
          </div>
        </div>
        <div className="col-md-12">
          <div className="slip-info space-between">
            <span className="name no-wrap">Funeral Date & Time </span>
            <span className="text">
              {moment(props.details.funeral_date, "DD/MM/YYYY").format("dddd")}{" "}
              {moment(props.details.funeral_date, "DD/MM/YYYY").format("ll")}
              {" at "}
              {props.details.funeral_time}
            </span>
          </div>
        </div>
      </div>
      <div
        className="center-hor space-around width-100 margin-0 "
        style={{ padding: "0px 15px" }}
      >
        <div className="width-33 absolute-center">
          <div className="width-100 center-hor">
            <div className="width-10 center-hor">
              <MdPhone className="icon xlarge" />{" "}
            </div>
            <div className="width-90 padding-10 text-center contact-text">
              02 9737 8877
            </div>
          </div>
        </div>
        <div className="width-33 absolute-center">
          <div className="width-100 center-hor">
            <div className="width-10 center-hor">
              <MdEmail className="icon xlarge" />{" "}
            </div>
            <div className="width-90 padding-10 text-center contact-text">
              info@raysflorist.com.au
            </div>
          </div>
        </div>
        <div className="width-33 absolute-center">
          <div className="width-100 center-hor">
            <div className="width-10 center-hor">
              <IoMdGlobe className="icon xlarge" />{" "}
            </div>
            <div className="width-90 padding-10 text-center contact-text">
              www.raysflorist.com.au
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
