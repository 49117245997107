export const data = {
  products: {
    // prd01: {name:'Product Name 1', amount: 1, price:100},
    prd01: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Casket Cover",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd02: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Heart",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd03: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Wearth",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd04: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Heart",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd05: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Bowel",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd06: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Heart",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd07: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Casket Cover",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd08: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Wearth",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd09: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Heart",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd010: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Wearth",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd011: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Wearth",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd012: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Bowel",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd013: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Bowel",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd014: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Bowel",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd015: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Casket Cover",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd016: {
      name: "Traditional Seasonal White & Yellow Casket Spray ",
      amount: 1,
      type: "Casket Cover",
      price: { small: 100, medium: 200, large: 300 },
    },
  },
  tribuites: {
    prd01: {
      name: "Tribuite Name 1",
      amount: 1,
      type: "tribute",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd02: {
      name: "Tribuite Name 2",
      amount: 1,
      type: "tribute",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd03: {
      name: "Tribuite Name 3",
      amount: 1,
      type: "tribute",
      price: { small: 100, medium: 200, large: 300 },
    },
    prd04: {
      name: "Tribuite Name 4",
      amount: 1,
      type: "tribute",
      price: { small: 100, medium: 200, large: 300 },
    },
  },
};

export const keyToNameList = {
  fd_code: "FD Code",
  ordered_by: "Ordered By",
  orderer_phone: "Phone Number",
  late_person_name: "Late person`s Name",
  funeral_date: "Funeral Date",
  funeral_time: "Funeral Time",
  tributes_delivery_time: "Tributes Delivery Time",
  funeral_location: "Funeral Location",
  casket_cover_delivery_time: "Casket Cover Delivery Time",
  tribuite_delivery_location: "Tributes Delivery Location",
  casket_cover_delivery_location: "Casket Cover Delivery Location",
  payment_option: "Payment Option",
  payment_email: "Invoice Email",
  price: "Price ($)",
  customPrice: "Custom Price ($)",
  amount: "Amount (Pcs)",
  sash_message: "Sash Message",
  card_message: "Card Message",
  order_message: "Order Comment",
  comment: "Comment",
  name: "Name",
  product_name: "Product Name",
  order_type: "Order Type",
  lidType: "Lid Type",
  product_type: "Product Type",
  size: "Size",
  type: "Type",
  start: "Task Assigned at",
  ended: "Task Ended at",
  checked_1: "First Check",
  checked_2: "Second Check",
  checked_3: "Final Check",
  time_difference: "Time Taken",
  code: "Code",
  image: "Picture",
  content: "Content",
  description: "Description",
  company_name: "Company Name",
  recipient_name: "Recipient`s Name",
  recipient_phone: "Recipient`s Phone",
  delivery_location: "Delivery Location",
  delivery_date: "Delivery Date",
  delivery_day: "Delivery Day",
  delivery_area: "Delivery Area",
  time_of_delivery: "Delivery Time",
  frequency: "Frequency",
  order_start_date: "Order Start On",
};

export const ProductTypes = [
  "Casket cover",
  "Heart",
  "Bowl",
  "Wreath",
  "Sprays",
  "Croatian Tributes",
  "Cross",
  "Letters",
  "Arrangement",
  "Singles/Petals",
  "Small Petals",
  "Large Petals",
  "Singles",
  "Bookspray",
  "Other",
];

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wendsday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const ThemeColor = [
  "hsl(192.21deg 32.53% 67.45%)",
  "hsl(239.62deg 32.53% 67.45%)",
  "hsl(76.54deg 32.53% 67.45%)",
  "hsl(304.23deg 32.53% 67.45%)",
  "hsl(88.85deg 32.53% 67.45%)",
];

export const EaselProducts = [
  "Wreath",
  "Heart",
  "Cross",
  "Sprays",
  "Letters",
  "Board",
];
//test
// export const BASE_SERVER_URL = "https://octopus-app-27yft.ondigitalocean.app";

//main
export const BASE_SERVER_URL =
  "https://hammerhead-app-2-uaqvn.ondigitalocean.app";

// //
// export const BASE_SERVER_URL = "http://localhost:9999";

export const BASE_SPACE_URL = "https://rfspaces.nyc3.digitaloceanspaces.com/";
