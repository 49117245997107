import DateTimePicker from "react-datetime-picker";
import React from "react";
import moment from "moment";
import "./style.css";
import { DateRangePickerOverlay } from "../DateRangePickerOverlay";
import { useState } from "react";
import { BsCalendarFill } from "react-icons/bs";
import {
  DatePickerSmall,
  ParentOrderSearchResultList,
  TextInputField,
  ToReadableDate,
} from "../../components";
import { SearchedWorkOrderList } from "../SearchedWorkOrdersList";
import { useOutsideAlerter } from "../../script";

export default function PrintPageSearchPanel(props) {
  const [searchResult, setSearchResult] = useState([]);

  const [listDate, setListDate] = React.useState("");

  const nav = React.useRef(null);

  useOutsideAlerter(nav, () => {
    props.setActive(false);
  });

  return (
    <div
      ref={nav}
      className={
        "order-side-slide searchWO light-box-shadow " +
        (props.active ? "active" : "")
      }
    >
      <div class="title thin center-hor justify-end">
        <div class="width-100 center-hor margin-10 float-left hidden">
          <span
            className=" font-medium space-between  center-hor no-wrap"
            style={{}}
          >
            <span className="center-hor">
              {/* <span className="color-gold bold-1">
                {moment(listDate, "DD/MM/YYYY").format("LL")}
              </span> */}
            </span>
            <span style={{ width: "100%" }}>
              <DatePickerSmall
                value={listDate}
                onChageSetter={(a) => {
                  setListDate(ToReadableDate(a.value, [], "/"));
                }}
                name="delivery_date"
                label="Delivery Date"
                addClass="flat"
                icon={<BsCalendarFill className="icon white" />}
                placeHolder="Delivery Date"
                light={true}
                clearDate={() => {
                  setListDate("");
                }}
              />
            </span>
          </span>
        </div>
      </div>

      <div className="padding-10" style={{ marginTop: "0" }}>
        <TextInputField
          value={(props.searchedWorkOrder || {}).late_person_name}
          listRelated={true}
          onlyPassSearchResults={true}
          searchPass={(a) => {
            setSearchResult(a);
          }}
          listRelatedOrder="workorder"
          selectedParent={props.searchedWorkOrder}
          selectResultVal={(a) => {
            props.selectResultVal(a);
          }}
          onChageSetter={(a) => {
            // setProperty(a, "order");
          }}
          name="print_order"
          addClass="stretch"
          placeHolder="Search Work Order"
        />
        <div className="searched-workorders">
          <SearchedWorkOrderList
            // setFocus={setFocus}
            selectResultVal={(a) => {
              console.log("search result ", a);
              // setSelectedParent(a);
              props.selectResultVal(a);
            }}
            data={searchResult}
            show={props.show || "workorder"}
            showLimit={20}
          />
        </div>
      </div>
    </div>
  );
}
