import { AiOutlineLink } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { MdCancel, MdLocationOn } from "react-icons/md";
import { shorten_string, ToReadableDate } from "../../components";

export function SearchedWorkOrderList(props) {
  // console.log('searchResult ',props.data);
  let data = props.data.slice(0, props.showLimit || 5);

  return data.map((funeral, index) => {
    return (
      <div
        key={index}
        style={props.linked ? { marginBottom: "15px !important" } : {}}
        className={
          "relative width-100 row search-result-row light-box-shadow touchable " +
          props.addClass
        }
        onClick={() => {
          // props.setFocus(false);
          props.selectResultVal(funeral);
        }}
      >
        <span className="col-md-12 col-sm-12 bold-1">
          {funeral.late_person_name}
        </span>
        <div className="padding-0 col-md-12 col-sm-12">
          <div className="row">
            <div className="col-md-5 col-sm-8 ">
              <span>
                <MdLocationOn className="icon icon-small width-auto" />{" "}
                {shorten_string(
                  JSON.parse(funeral.funeral_location || "{}").name
                )}
              </span>
            </div>
            <div className="col-md-3 col-sm-4">
              <span>
                <BiTimeFive className="icon icon-small width-auto" />{" "}
                {ToReadableDate(
                  funeral.funeral_date,
                  [],
                  "/",
                  "ll",
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
            <div className="col-md-4 col-sm-4 ">
              <span className="width-100 center-hor font-xmedium">
                Ordered By
                <span className="bold-1" style={{ marginLeft: "4px" }}>
                  {funeral.ordered_by}
                </span>
              </span>
            </div>
          </div>
        </div>
        {/* {//console.log('the funeral ',funeral)} */}
        {/* {props.linked ? (
          <span className="icon-link absolute-center">
            <MdCancel
              onClick={() => {
                props.unLink();
              }}
              className="icon red width-100 mid white"
            />
          </span>
        ) : (
          <span className="icon-link absolute-center">
            <AiOutlineLink
              onClick={() => {
                // props.setFocus(false);
                props.selectResultVal(funeral);
              }}
              className="icon width-100 mid white"
            />
          </span>
        )} */}
      </div>
    );
  });
}
