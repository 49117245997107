// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slip-container {
  margin: 10px;
  padding: 10px 5px;
  border: 1px dashed grey;
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 1200px; */
  /* aspect-ratio: 7/4.8; */
  aspect-ratio: 7/4;
  /* height: 300px; */
  border-radius: 10px;
  background-color: #fff6e9;
}
.slip-info {
  margin-bottom: 30px;
}
.logo-img > img {
  margin: 10px 0px 5px 10px;
  height: 35px;
}
.slip-logo {
  border-bottom: 1px solid #f7ecd5;
  padding-bottom: 20px;
}
.slip-info > .name {
  padding: 0px 10px;
  font-weight: bold;
  font-size: 16px !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.slip-info > .text {
  font-family: system-ui;
  font-size: 16px !important;
  border-bottom: 1px dotted grey;
}
.contact-text {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SlipPrintLayout/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,gCAAgC;EAChC,oBAAoB;AACtB;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,0BAA0B;EAC1B,wEAAwE;AAC1E;AACA;EACE,sBAAsB;EACtB,0BAA0B;EAC1B,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB","sourcesContent":[".slip-container {\n  margin: 10px;\n  padding: 10px 5px;\n  border: 1px dashed grey;\n  width: 700px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  /* width: 1200px; */\n  /* aspect-ratio: 7/4.8; */\n  aspect-ratio: 7/4;\n  /* height: 300px; */\n  border-radius: 10px;\n  background-color: #fff6e9;\n}\n.slip-info {\n  margin-bottom: 30px;\n}\n.logo-img > img {\n  margin: 10px 0px 5px 10px;\n  height: 35px;\n}\n.slip-logo {\n  border-bottom: 1px solid #f7ecd5;\n  padding-bottom: 20px;\n}\n.slip-info > .name {\n  padding: 0px 10px;\n  font-weight: bold;\n  font-size: 16px !important;\n  font-family: \"Franklin Gothic Medium\", \"Arial Narrow\", Arial, sans-serif;\n}\n.slip-info > .text {\n  font-family: system-ui;\n  font-size: 16px !important;\n  border-bottom: 1px dotted grey;\n}\n.contact-text {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
