import React, { useEffect, useRef, useState, createRef } from "react";
import {
  PageLoading,
  ToReadableDate,
  replaceCharacter,
  RunSheetPrintPage,
  LoadingDiv,
  CalcTimeDifference,
  TableRowSeparator,
  shorten_string,
  TextInputField,
  ButtonFlat,
  LabeledCheckBox,
  toName,
} from "../components";
import { BASE_SERVER_URL } from "../sampleData";
import axios from "axios";
import { useParams } from "react-router-dom";
import { RunsPrintPriview } from "./deliveryRunList";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../images/logo.png";
import {
  MdCancel,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdFactCheck,
  MdPrint,
  MdOutlineError,
} from "react-icons/md";

import { BsCheckCircleFill } from "react-icons/bs";

import moment from "moment";

import ReactToPrint from "react-to-print";
import { AiFillCheckCircle, AiOutlineCheck } from "react-icons/ai";
import { FaRegCheckCircle, FaPrint } from "react-icons/fa";

import { PopUpAlert } from "../components/movableDragDropArea";

import { sendPrintRequest } from "../handlers/printRequestHandlers";
import { Typewriter } from "../utils";
import PrintPageSearchPanel from "../components/PrintPageSearchPanel/index.js";
import SlipPrintLayout from "../components/SlipPrintLayout/index.js";

function MergeWorkOrders_WORKORDERS(order) {
  // console.log('mergesssing', order)

  let objs = {};
  order.forEach((each, index) => {
    // console.log('mergesss ',index,objs,each.parent_funeral_id)
    objs[each.parent_funeral_id] = objs[each.parent_funeral_id]
      ? [...objs[each.parent_funeral_id], each]
      : [each];
  });
  // console.log('fetched data filter list 11',order,objs)
  return objs;
}

function MergeWorkOrders(order) {
  if ((order || []).length === 0) {
    return [];
  }
  let objs = {};
  order.forEach((each, index) => {
    try {
      if (each.order_type === "Funeral") {
        let locName = JSON.parse(each.location || "{}");
        // locName = locName.coordinates.lat+'_'+locName.coordinates.lng
        locName = locName.coordinates
          ? locName.coordinates.lat + "_" + locName.coordinates.lng
          : replaceCharacter(locName.name, " ", "").toLowerCase();
        objs[each.parent_funeral_id + "@" + locName] = objs[
          each.parent_funeral_id + "@" + locName
        ]
          ? [...objs[each.parent_funeral_id + "@" + locName], each]
          : [each];
      } else {
        objs[each.work_order_id] = [each];
      }
    } catch (err) {}
  });

  let value = [];
  Object.keys(objs).forEach((each, index) => {
    let one = { ...objs[each][0] };
    let products = [];
    delete one.products;

    objs[each].forEach((each2, index) => {
      products = [
        ...products,
        ...(typeof each2.products === "string"
          ? JSON.parse(each2.products)
          : each2.products),
      ];
    });
    one.products = products;
    value[index] = one;
  });

  return value;
}

const PrintButton = ({ printableComponent, type, slip }) => {
  const componentRef = useRef();
  let pageSizes = {
    workorders: "a3 landscape; margin:  0cm;",
    workorder: "a4 portrait; margin:  0cm;",
    workorderSlip: "a5 landscape; margin:  0cm;",
    deliveries: "a3 landscape; margin:  0cm;",
    runsheet: "a4 landscape; margin: 0cm;",
  };
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <button className="print-btns">
            Print {slip ? "Slip" : "Paper"}
          </button>
        )}
        content={() => componentRef.current}
        // pageStyle={`@page { size: ${pageSizes[type]} }`}
      />

      {React.cloneElement(printableComponent, { ref: componentRef })}
    </div>
  );
};

export function Print(props) {
  const [font, setFont] = useState(13);
  const [printInfo, setPrintInfo] = useState({});
  const [searchedWorkOrder, setSearchedWorkOrder] = useState({});
  const [active, setActive] = useState(false);
  const [slipPreview, setSlipPreview] = useState(false);

  useEffect(() => {
    console.log("change in order passed first ", searchedWorkOrder);
  }, [searchedWorkOrder]);

  return (
    <div className="width-100 relative">
      <div
        className="printer-panel no-print center-hor light-box-shadow"
        style={{ zIndex: "10" }}
      >
        <span
          className=" absolute-center gold-themed font-medium bold-1"
          style={{ padding: "13px 20px" }}
        >
          Print Page
        </span>
        <div className="space-around center-hor" style={{ width: "1000px" }}>
          <div className="center-hor">
            <span className="font-gold font-medium no-wrap margin-10">
              Font Size{" "}
            </span>
            <div className="center-hor" style={{ width: "70px" }}>
              <TextInputField
                value={font}
                placeHolder=""
                type="number"
                name="font"
                onChageSetter={(a) => {
                  setFont(a.value);
                }}
                style={{ height: "30px !important" }}
                addInputClass={" light-border"}
                addClass={"text-center width-100 flat"}
              />
            </div>
          </div>
          <div className="center-hor">
            <div>{/* <MdPrint className="icon mid gold" /> */}</div>
            <PrintButton
              slip={slipPreview}
              type={slipPreview ? "workorderSlip" : props.print}
              printableComponent={
                slipPreview ? (
                  <PrintSlipPage orderDetail={searchedWorkOrder} />
                ) : (
                  <PrintPage
                    setSelectedOrder={(a) => {
                      setSearchedWorkOrder(a);
                    }}
                    changePrintInfo={(a) => {
                      setPrintInfo(a);
                    }}
                    props={props}
                    orderDetail={searchedWorkOrder}
                    font={font}
                  />
                )
              }
            />
          </div>
          <div>
            {props.print === "workorder" &&
            searchedWorkOrder.type === "Funeral" ? (
              <button
                className={"print-btns " + (slipPreview ? "active" : "")}
                onClick={() => {
                  setSlipPreview((prev) => !prev);
                }}
              >
                {!slipPreview ? "Print Funeral Slip" : "Work Order Preview"}
              </button>
            ) : (
              ""
            )}
          </div>
          <div>
            {props.print === "workorder" && !slipPreview ? (
              <button
                className="print-btns"
                onClick={() => {
                  (printInfo || {}).LablePrintAction();
                }}
              >
                Print Label
              </button>
            ) : (
              ""
            )}
          </div>
          <div>
            {props.print === "workorder" && false ? (
              <button
                className="print-btns"
                onClick={() => {
                  setActive(true);
                }}
              >
                Seach Work Order
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div></div>
      </div>
      <div>
        <PrintPageSearchPanel
          active={active}
          setActive={(a) => {
            setActive(a);
          }}
          selectedParent={searchedWorkOrder}
          selectResultVal={(a) => {
            setSearchedWorkOrder(a);
            // setSelectedParentOrderID(a._id);
          }}
        />
      </div>
    </div>
  );
}

function PdfViewer(props) {
  return (
    <div
      className="pdf-container"
      style={{
        width: "304px",
        height: "192px",
        border: "none",
        overflow: "hidden",
      }}
    >
      <iframe
        title="Label"
        src={props.url}
        style={{ width: "100%", height: "100%", border: "none" }}
      ></iframe>
    </div>
  );
}

function LabelPrintPrompt(props) {
  const [edit, setEdit] = useState(true);
  const [refresh, setRefresh] = useState(1);
  const [alertInfo, setAlertInfo] = useState({});
  const [selectedForPrint, setSelectedForPrint] = useState(
    props.detail.type === "Funeral"
      ? Object.keys(props.detail.tributes || []).map((a) => {
          return Number(a);
        })
      : [0, 1]
  );

  const AlertIcons = {
    success: <BsCheckCircleFill className="icon green large" />,
    error: <MdOutlineError className="icon red large" />,
    causion: <BsCheckCircleFill className="icon yellow large" />,
  };
  let labelNum =
    props.detail.type === "Funeral" ? (props.detail.tributes || []).length : 2;

  labelNum = labelNum <= 4 ? labelNum : 4;

  useEffect(() => {
    if (props.detail.type === "Funeral" && props.detail.tributes.length === 0) {
      setAlertInfo({
        title: "Tributes not found",
        message:
          "Please include at least one tribute product to generate label",
      });
    }

    setSelectedForPrint(
      props.detail.type === "Funeral"
        ? Object.keys(props.detail.tributes || []).map((a) => {
            return Number(a);
          })
        : [0, 1]
    );
  }, [props.detail]);

  function toggleAllSelect() {
    if (selectedForPrint.length) {
      setSelectedForPrint([]);
    } else {
      setSelectedForPrint(
        Object.keys(props.detail.tributes || []).map((a) => {
          return Number(a);
        })
      );
    }
  }

  return (
    <div
      className={
        "popup fullscreen absolute-center " +
        ((props.info || {}).blurBackground ? "blur-bg" : "")
      }
      style={{ height: "auto", position: "absolute" }}
    >
      {Object.keys(alertInfo).length > 0 ? (
        <PopUpAlert
          info={alertInfo}
          onClose={() => {
            setAlertInfo({});
            props.onClose();
          }}
        />
      ) : props.detail.type === "Funeral" &&
        props.detail.tributes.length === 0 ? (
        ""
      ) : (
        <div
          className={
            "popup-alert relative light-box-shadow " +
            (props.info || {}).cardClass
          }
          style={{
            borderRadius: edit ? "8px 0 0 8px" : "8px",
            width: eval(labelNum * 304 + 18) + "px",
          }}
        >
          <div className="width-100 absolute-center relative" style={{}}>
            <div
              className={
                !edit
                  ? "label-info-edit "
                  : "label-info-edit active light-box-shadow"
              }
            >
              <div className={"label-info-edit-body "}>
                <span className="">Edit Label Detail</span>
                {props.detail.type === "Funeral"
                  ? [
                      <TextInputField
                        placeHolder="Late Persons Name"
                        addClass={""}
                        value={props.detail.latePerson}
                        name={"latePerson"}
                        onChageSetter={(a) => {
                          props.setLabelInfo({ [a.name]: a.value });
                        }}
                      />,
                    ]
                  : [
                      <div className="row width-100">
                        <div className="col-md-6">
                          <TextInputField
                            placeHolder="Recipient Name"
                            addClass={""}
                            value={props.detail.recipient}
                            name={"recipient"}
                            onChageSetter={(a) => {
                              props.setLabelInfo({ [a.name]: a.value });
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextInputField
                            placeHolder="Phone Number"
                            value={props.detail.phoneNumber}
                            name={"phoneNumber"}
                            onChageSetter={(a) => {
                              props.setLabelInfo({ [a.name]: a.value });
                            }}
                          />
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-12">
                          <TextInputField
                            placeHolder="Location"
                            value={props.detail.location}
                            name={"location"}
                            onChageSetter={(a) => {
                              props.setLabelInfo({ [a.name]: a.value });
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          <TextInputField
                            placeHolder="Card Message"
                            textArea={true}
                            rows={1}
                            domId={"autoExpand"}
                            value={props.detail.card_message}
                            name={"card_message"}
                            onChageSetter={(a) => {
                              props.setLabelInfo({ [a.name]: a.value });
                            }}
                          />
                        </div>
                      </div>,
                    ]}
                <ButtonFlat
                  dotColor="#eaeaea"
                  addClass="font-medium thin-flat border-radius-8 width-100 margin-top-50"
                  style={{
                    background: "#eaeaea",
                    width: "100%",
                    height: "35px",
                  }}
                  loading={false}
                  value={"Generate Label"}
                  clickTo={() => {
                    setRefresh((a) => a + 1);
                  }}
                />
              </div>
            </div>
            <div
              className="width-100 text-center row font-medium"
              style={{
                padding: "20px 5px 30px",
                background: "#525659",
                zIndex: "10",
                borderRadius: edit ? "8px 0 0px 8px" : "8px",
                minHeight: "350px",
              }}
            >
              <p className={"bold-1 relative " + (props.info || {}).titleClass}>
                {(props.info || {}).title}
                {props.detail.type === "Funeral" ? (
                  <LabeledCheckBox
                    label="Select All"
                    setter={() => {
                      toggleAllSelect();
                    }}
                    checked={Boolean(selectedForPrint.length)}
                    addClass="float-right-top mid"
                  />
                ) : (
                  ""
                )}
              </p>
              {(props.detail || {}).type === "Funeral"
                ? props.detail.tributes.map((each, index) => {
                    return (
                      <LabelImageDisplay
                        labelInfoSetterPUSH={(a, cue) => {
                          props.setLabelInfoPUSH(a, cue);
                        }}
                        details={{
                          latePerson: props.detail.latePerson,
                          productCode: each.code,
                          sashPrice: each.sashPrice || 0,
                          sashMessage: each.sash_message || 0,
                          productPrice: each.customPrice
                            ? each.customPrice
                            : each.price,
                          comment: each.comment,
                          number: index,
                        }}
                        labelInfo={props.detail}
                        labelType="funeral"
                        refresh={refresh}
                        labelCue={index}
                        checkedLabels={selectedForPrint}
                        kickOutCue={(a) => {
                          setSelectedForPrint([...a]);
                        }}
                      />
                    );
                  })
                : [
                    <LabelImageDisplay
                      labelInfoSetter={(a) => {
                        props.setLabelInfo(a);
                      }}
                      details={props.detail}
                      labelType="retail"
                      refresh={refresh}
                      labelInfo={props.detail}
                      labelCue={0}
                      checkedLabels={selectedForPrint}
                      kickOutCue={(a) => {
                        setSelectedForPrint([...a]);
                      }}
                    />,
                    <LabelImageDisplay
                      labelInfoSetter={(a) => {
                        props.setLabelInfo(a);
                      }}
                      details={props.detail}
                      labelType="card"
                      refresh={refresh}
                      labelInfo={props.detail}
                      labelCue={1}
                      checkedLabels={selectedForPrint}
                      kickOutCue={(a) => {
                        setSelectedForPrint([...a]);
                      }}
                    />,
                  ]}
            </div>
          </div>

          <div
            className="width-100 center-hor"
            style={{
              position: "relative",
              zIndex: "10",
              background: "#525659",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <ButtonFlat
              dotColor="#eaeaea"
              addClass="font-medium thin-flat border-radius-8 width-50 margin-5"
              style={{ background: "#eaeaea", width: "100%", height: "35px" }}
              loading={false}
              value={(props.info || {}).cancelValue || "Close"}
              clickTo={() => {
                props.onClose();
              }}
            />
            <ButtonFlat
              dotColor="#eaeaea"
              addClass="font-medium thin-flat border-radius-8 width-50 margin-5"
              style={{ background: "#eaeaea", width: "100%", height: "35px" }}
              loading={false}
              value={"Edit"}
              // disabled={!selectedForPrint.length ? "disabled" : ""}
              clickTo={() => {
                setEdit((a) => {
                  // window.alert(a);
                  return !a;
                });
              }}
            />
            <ButtonFlat
              dotColor="#eaeaea"
              addClass="font-medium thin-flat warnning-btn border-radius-8 width-50 margin-5"
              style={{ background: "#eaeaea", width: "100%", height: "35px" }}
              loading={false}
              loadingValue={(props.info || {}).loading}
              disabled={!selectedForPrint.length ? "disabled" : ""}
              value={
                "Print " +
                selectedForPrint.length +
                " Label" +
                (selectedForPrint.length > 1 ? "s" : "")
              }
              clickTo={() => {
                props.callback(selectedForPrint);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export function LabelImageDisplay(props) {
  // const [refresh, setRefresh] = useState(props.refresh || 1);
  const [loading, setLoading] = useState(true);
  const [labelURL, setLabelURL] = useState("");
  // let BASE = "http://localhost:9999";
  let BASE = BASE_SERVER_URL;

  function refreshPDF() {
    let url = BASE + "/generate-pdf/" + props.labelType;
    let data = props.details;
    setLoading(true);
    setLabelURL("");

    axios.post(url, data).then((response) => {
      if (response.data.success) {
        setLoading(false);
        setLabelURL(BASE + "/" + response.data.url);
        // window.open(BASE + "/" + response.data.url, "_blank");
        if (props.labelType === "funeral") {
          props.labelInfoSetterPUSH(
            BASE + "/" + response.data.url,
            props.labelCue
          );
        } else {
          props.labelInfoSetter({
            ["pdfURL_" + props.labelType]: BASE + "/" + response.data.url,
          });
        }
      }
    });
  }

  useEffect(() => {
    refreshPDF();
  }, [props.refresh]);

  function toggleCue() {
    if (Boolean((props.checkedLabels || []).includes(props.labelCue))) {
      return removeItemFromArray(props.checkedLabels, props.labelCue);
    } else {
      let x = [...props.checkedLabels];
      x.push(props.labelCue);
      return x;
    }
  }

  return loading ? (
    <div
      className="print-label-img absolute-center"
      style={{
        background: "white",
        width: "304px",
        margin: "1px",
        padding: "0px",
      }}
    >
      <span className="text">Generating Label...</span>
      <LoadingDiv
        height={"185px"}
        style={{ width: "100%" }}
        amount={0}
        speed={2}
      />
    </div>
  ) : (
    <div style={{ width: "304px", padding: "0px" }}>
      {/* {props.labelType === "funeral" ? ( */}
      <LabeledCheckBox
        label=""
        setter={() => {
          props.kickOutCue(toggleCue());
        }}
        addClass="mid"
        checked={Boolean((props.checkedLabels || []).includes(props.labelCue))}
      />
      {/* ) : (
        ""
      )} */}
      <PdfViewer url={labelURL} />
    </div>
  );
}
function removeItemFromArray(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

function OrderedProductsPrint(props) {
  const [orders, setOrders] = useState({});
  const [orderCounts, setOrderCounts] = useState({});
  const [listIsLoading, setListIsLoading] = useState(false);
  const [listDate, setListDate] = useState(props.date);

  function refreshList() {
    setListIsLoading(true);
    let url = BASE_SERVER_URL + "/wo/getOrderedProducts";
    // let url = BASE_SERVER_URL + "/TestPopulate";

    axios
      .post(url, { date: listDate, mergeOrders: true })
      // .get(url)
      .then((doc) => {
        if (doc.data.status === "success") {
          setListIsLoading(false);
          setOrders(doc.data.response.products);
          setOrderCounts({
            orderCount: doc.data.response.orderCount,
            productCount: doc.data.response.productCount,
          });
        } else {
        }
      })
      .catch(function (error) {
        // console.log(error)
      });
  }

  useEffect(() => {
    refreshList();
  }, []);
  return (
    <div className="the-print-page print-page display-flex">
      {/* <div ref={pageStyleRef} id='someID' style={{ display: 'none' }} /> */}

      <div className="relative width-100 flex-grow">
        <div className="width-100 center-hor" style={{ marginBottom: "10px" }}>
          <div className="absolute-center" style={{ width: "150px" }}>
            <img src={logo} style={{ width: "100px" }} />
          </div>
          <div className="width-100">
            <div className="head page center-hor relative ">
              <span
                className="absolute-center date xlight-box-shadow"
                style={{ fontSize: props.font + "px" }}
              >
                {ToReadableDate(props.date, [], "-", "dddd", "DD/MM/YYYY") +
                  " " +
                  ToReadableDate(props.date, [], "-", "ll", "DD/MM/YYYY") ||
                  "N/A"}
              </span>
              <span
                className="font-big absolute-center width-50"
                style={{ marginLeft: "0px" }}
              >
                Ordered Products
              </span>
            </div>
          </div>
        </div>

        <div className="width-100 center-hor padding-10"></div>

        <table
          style={{ borderRadius: "8px", overflow: "" }}
          className="table table-striped"
        >
          <thead>
            <tr
              className="bt_table printing"
              style={{ fontSize: props.font + "px" }}
            >
              <th scope="col" style={{ borderTopLeftRadius: "8px" }}>
                #
              </th>
              <th scope="col">Type</th>
              <th scope="col">Product Name</th>
              <th scope="col">Size</th>
              <th scope="col">Amount</th>
              <th scope="col">Price</th>
              <th
                scope="col"
                className="no-wrap"
                style={{ borderTopRightRadius: "8px", minWidth: "40%" }}
              >
                Flower Contents
              </th>
            </tr>
          </thead>

          <tbody className="auto">
            <tr style={{ fontSize: props.font + "px" }}>
              <td
                colSpan={"7"}
                className="font-large text-center bold-1 color-gold"
              >
                Funeral
              </td>
            </tr>

            {listIsLoading ? (
              <tr>
                <td colSpan={"7"} className="text-center">
                  <LoadingDiv
                    amount={0}
                    speed={2}
                    height="300px"
                    style={{ width: "100%", margin: "0px" }}
                    innerStyle={{ margin: "0px", width: "100%" }}
                  />
                </td>
              </tr>
            ) : (
              Object.keys(orders.Funeral || []).map((funeral_ID, at) => {
                let products = orders.Funeral[funeral_ID];
                return products.map((each, index) => {
                  return [
                    index === 0 ? (
                      <tr style={{ fontSize: props.font + "px" }}>
                        <td
                          className="font-small bold-1 color-gold"
                          colspan="7"
                        >
                          {each.late_person_name || ""}
                        </td>
                      </tr>
                    ) : (
                      ""
                    ),
                    <tr
                      key={index}
                      className="relative"
                      style={{ fontSize: props.font + "px" }}
                    >
                      <th scope="row">{index + 1}</th>
                      <td className="no-wrap">{each.product_type}</td>
                      <td className="">
                        <span className="width-100 block-display">
                          {each.product_name}
                        </span>
                        {each.comment ? (
                          <span className="width-100 font-small color-gold">
                            Comment: {each.comment || ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="no-wrap">{toName(each.size)}</td>
                      <td>{each.amount}</td>
                      <td>
                        ${each.customPrice ? each.customPrice : each.price}
                      </td>
                      <td className="font-mid">
                        {Object.keys(
                          JSON.parse(each.content || "{}")[each.size] || {}
                        ).map((roses, a) => {
                          return (
                            (a !== 0 ? ", " : "") +
                            roses +
                            " x " +
                            JSON.parse(each.content || "{}")[each.size][roses]
                          );
                        })}
                      </td>
                    </tr>,
                  ];
                });
              })
            )}
            <tr>
              <td
                colSpan={"7"}
                className="font-large text-center bold-1 color-gold"
              >
                Retail
              </td>
            </tr>
            {listIsLoading ? (
              <tr>
                <td colSpan={"7"} className="text-center">
                  <LoadingDiv
                    amount={0}
                    speed={2}
                    height="300px"
                    style={{ width: "100%", margin: "0px" }}
                    innerStyle={{ margin: "0px", width: "100%" }}
                  />
                </td>
              </tr>
            ) : (
              (orders.Retail || []).map((each, index) => {
                return (
                  <tr
                    key={index}
                    className="relative"
                    style={{ fontSize: props.font + "px" }}
                  >
                    <th scope="row">{index + 1}</th>
                    <td className="no-wrap">{each.product_type}</td>
                    <td className="center-ver-col">
                      <span className="width-100">{each.product_name}</span>
                      {each.comment ? (
                        <span className="width-100 font-small color-gold">
                          Comment: {each.comment || ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{each.amount}</td>
                    <td className="no-wrap">{toName(each.size)}</td>
                    <td>${each.price || 0}</td>
                    <td className="font-mid">
                      {Object.keys(
                        JSON.parse(each.content || "{}")[each.size] || {}
                      ).map((roses, a) => {
                        return (
                          (a !== 0 ? ", " : "") +
                          roses +
                          " x " +
                          JSON.parse(each.content || "{}")[each.size][roses]
                        );
                      })}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <div className="width-100 space-between">
          <div className="width-30">
            <table
              style={{ borderRadius: "8px", overflow: "" }}
              className="table table-striped"
            >
              <thead>
                <tr style={{ fontSize: props.font + "px" }}>
                  <th scope="col" className="text-left">
                    #
                  </th>
                  <th className="text-left">Product Type</th>
                  <th className="text-left">Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys((orderCounts || {}).productCount || {}).map(
                  (type, index) => {
                    return (
                      <tr style={{ fontSize: props.font + "px" }}>
                        <td>{index + 1}</td>
                        <td>{type}</td>
                        <td>{orderCounts.productCount[type]}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          <div className="width-30">
            <table
              style={{ borderRadius: "8px", overflow: "" }}
              className="table table-striped"
            >
              <thead>
                <tr style={{ fontSize: props.font + "px" }}>
                  <th scope="col" className="text-left">
                    #
                  </th>
                  <th className="text-left">Order Type</th>
                  <th className="text-left">Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys((orderCounts || {}).orderCount || {}).map(
                  (type, index) => {
                    return (
                      <tr style={{ fontSize: props.font + "px" }}>
                        <td>{index + 1}</td>
                        <td>{type}</td>
                        <td>{orderCounts.orderCount[type]}</td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export const PrintSlipPage = React.forwardRef((p, ref) => {
  const props = p.props;
  // console.log("fetched run data filter list ", p);
  return (
    <div
      ref={ref}
      className="absolute-center the-print-page print-page A5"
      style={{ marginTop: "50px" }}
    >
      <SlipPrintLayout details={p.orderDetail || {}} />;
    </div>
  );
});

export const PrintPage = React.forwardRef((p, ref) => {
  // props.hideHeader()
  const props = p.props;
  let font = p.font;

  let { filter, date } = useParams();
  const [selectedRun, setSelectedRun] = useState([]);
  const [listIsLoading, setListIsLoading] = useState(true);

  function fetchRuns() {
    // setListIsLoading(true)
    if (props.print === "deliveries") {
      let url = BASE_SERVER_URL + "/run/getAllBy";
      let filters = { ...JSON.parse(filter || "{}") };
      filters.find = {
        delivery_date: replaceCharacter(
          JSON.parse(filter || "{}").find.delivery_date,
          "-",
          "/"
        ),
      };
      axios
        .post(url, filters)
        .then((doc) => {
          if (doc.data.status === "success") {
            setSelectedRun(doc.data.response);
            setListIsLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.print === "runsheet") {
      let url = BASE_SERVER_URL + "/run/getRun/" + filter;
      axios
        .get(url)
        .then((doc) => {
          // console.log('mergesss obj ret objs in rerere',doc.data.response[0])
          if (doc.data.status === "success") {
            setSelectedRun(doc.data.response[0]);
            setListIsLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.print === "workorders") {
      let url = BASE_SERVER_URL + "/wo/getAllBy/";
      let find = { ...JSON.parse(filter) };
      if (find.filter.type === "Funeral") {
        find.filter.funeral_date = replaceCharacter(
          find.filter.funeral_date || "",
          "-",
          "/"
        );
      } else {
        find.filter.delivery_date = replaceCharacter(
          find.filter.delivery_date || "",
          "-",
          "/"
        );
      }
      axios
        .post(url, find)
        .then((doc) => {
          // console.log('input deliveries ret',doc.data.response, MergeWorkOrders(doc.data.response))
          if (doc.data.status === "success") {
            setSelectedRun(
              find.filter.type === "Funeral"
                ? MergeWorkOrders_WORKORDERS(doc.data.response, false)
                : doc.data.response
            );
            setListIsLoading(false);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  useEffect(() => {
    fetchRuns();
  }, []);

  if (listIsLoading) {
    // return <PageLoading noLogo={true} />
  }
  const [listPerPage, setListPerPage] = useState(15);
  let dateDisplay = "";
  if (props.print === "workorders") {
    dateDisplay =
      JSON.parse(filter || "{}").filter.funeral_date ||
      JSON.parse(filter || "{}").filter.delivery_date;
  }

  const [alert, setAlert] = useState({});
  const [lableInfo, setLableInfo] = useState({});

  const [printInfoPage, setPrintInfoPage] = useState({});
  useEffect(() => {
    setPrintInfoPage({
      LablePrintAction: () => {
        setAlert({
          title: "Label Preview",
          message: " ",
          type: "success",
          // displayIcon: <FaPrint className="icon green large" />,
          noIcon: true,
          confirmPrompt: true,
          cardClass: "grey-bg",
          titleClass: "color-white font-big",
          cancelValue: "Close",
          confirmValue: "Print Label",
          loading: false,
          htmlMEssage: "",
          blurBackground: true,
          callback: () => {},
        });
      },
    });
  }, [lableInfo]);

  useEffect(() => {
    p.changePrintInfo(printInfoPage);
  }, [printInfoPage]);

  function sendPrint(allToPrint) {
    setAlert((b) => {
      return { ...b, loading: true };
    });
    let pdfUrls = [];

    let pdfs =
      lableInfo.type === "Funeral"
        ? lableInfo["pdfURL_funeral"]
        : [lableInfo["pdfURL_retail"], lableInfo["pdfURL_card"]];
    Object.keys(pdfs).forEach((key) => {
      let each = pdfs[key];
      // console.log("Props loff to print loop ", key);
      if (allToPrint.includes(Number(key))) {
        pdfUrls.push(each);
      }
    });
    axios
      .post(BASE_SERVER_URL + "/print/label", { pdfUrls: pdfUrls })
      .then((a) => {
        // console.log("Print lable for retail ", a);
        a = a.data;
        setAlert({
          type: a.success ? "success" : "error",
          title: a.success
            ? "Label Printed Successfully"
            : "Error Printing Workorder Label",
          message: a.success
            ? "Funeral Workorder label printed successfully"
            : a.message,
        });
      });
  }

  return (
    <div ref={ref} className="A4">
      {Object.keys(alert).length > 0 && (
        <LabelPrintPrompt
          detail={lableInfo}
          setLabelInfoPUSH={(a, cue) => {
            setLableInfo((prev) => {
              let newObj = JSON.parse(JSON.stringify(prev));
              newObj.pdfURL_funeral = newObj.pdfURL_funeral || {};
              if (!newObj.pdfURL_funeral[cue]) {
                newObj.pdfURL_funeral[cue] = a;
              }
              return newObj;
            });
          }}
          setLabelInfo={(a) => {
            setLableInfo((prev) => {
              let newObj = { ...prev };
              return { ...newObj, ...a };
            });
          }}
          generatePDF={() => {
            p.changePrintInfo(printInfoPage);
          }}
          info={alert}
          onClose={() => {
            setAlert({});
          }}
          callback={(a) => {
            sendPrint(a);
          }}
        />
      )}
      {props.print === "runsheet" ? (
        <RunSheetPrintPage
          date={
            ToReadableDate(
              selectedRun.delivery_date,
              [],
              "-",
              "dddd",
              "DD/MM/YYYY"
            ) +
              " " +
              ToReadableDate(
                selectedRun.delivery_date,
                [],
                "-",
                "ll",
                "DD/MM/YYYY"
              ) || "N/A"
          }
          deliveries={MergeWorkOrders(selectedRun.deliveries) || []}
          font={font}
          //    type={JSON.parse(filter || '{}').filter.type}
          detail={selectedRun}
        />
      ) : props.print === "deliveries" ? (
        <RunsPrintPriview
          date={
            ToReadableDate(
              JSON.parse(filter || "{}").find.delivery_date,
              [],
              "-",
              "dddd",
              "DD/MM/YYYY"
            ) +
              " " +
              ToReadableDate(
                JSON.parse(filter || "{}").find.delivery_date,
                [],
                "-",
                "ll",
                "DD/MM/YYYY"
              ) || "N/A"
          }
          data={selectedRun}
        />
      ) : props.print === "workorders" ? (
        <RunSheetPrintPage
          pageGaps={listPerPage}
          setListNum={(a) => {
            setListPerPage(a);
          }}
          wos={true}
          date={
            ToReadableDate(dateDisplay, [], "-", "dddd", "DD/MM/YYYY") +
              " " +
              ToReadableDate(dateDisplay, [], "-", "ll", "DD/MM/YYYY") || "N/A"
          }
          deliveries={selectedRun}
          type={JSON.parse(filter || "{}").filter.type}
          detail={{ deliveries: selectedRun }}
          font={font}
          //    printButton={<PrintButton />}
        />
      ) : props.print === "ordered-products" ? (
        <OrderedProductsPrint
          printType={props.print}
          pageGaps={listPerPage}
          date={replaceCharacter(filter || "", "-", "/")}
          deliveries={selectedRun}
          type={"ordered-products"}
          detail={{ deliveries: selectedRun }}
          font={font}
          //    printButton={<PrintButton />}
        />
      ) : props.print === "workorder" ? (
        <WorkOrderPrint
          font={font}
          setSelectedOrder={p.setSelectedOrder}
          id={filter}
          orderDetail={p.orderDetail || {}}
          setData={(a) => {
            console.log("fetched run ", a);
            setLableInfo(a);
          }}
          date={date}
        />
      ) : (
        ""
      )}
    </div>
  );
});

export function WorkOrderPrint(props) {
  // props.hideHeader()
  const [selectedWO, setSelectedWO] = useState({});
  const [listIsLoading, setListIsLoading] = useState(true);
  function fetch() {
    // let url = BASE_SERVER_URL+'/wo/getWo/6477c978347188fd73243996'
    let url = BASE_SERVER_URL + "/wo/getWo/" + props.id;

    axios
      .get(url)
      .then((doc) => {
        // console.log('fetched run data filter list ',doc.data.response)
        if (doc.data.status === "success") {
          setSelectedWO(doc.data.response[0]);
          props.setSelectedOrder(doc.data.response[0]);

          let labelDetails = {
            type: doc.data.response[0].type,
            latePerson: (
              doc.data.response[0].late_person_name || "Late Person Name - N/A"
            ).toUpperCase(),
            comment: (
              doc.data.response[0].order_comment || "Comment - N/A"
            ).toUpperCase(),
            productCode: (
              doc.data.response[0].serial_no || "Code - N/A"
            ).toUpperCase(),
            tributes:
              JSON.parse(doc.data.response[0].tributes_selected || "[]") || [],

            name: doc.data.response[0].recipient_name || " ",
            signoff: "Regards",
            card_message:
              doc.data.response[0].card_message || "Card Message - N/A",
            recipient:
              doc.data.response[0].recipient_name || "Recipient Name - N/A",
            location:
              JSON.parse(doc.data.response[0].delivery_location || "{}").name ||
              "Location - N/A",
            phoneNumber:
              doc.data.response[0].recipient_phone || "Contact - N/A",
          };

          Object.keys(labelDetails).forEach((each) => {
            if (
              typeof labelDetails[each] === "string" &&
              !["card_message", "comment"].includes(each)
            ) {
              labelDetails[each] = labelDetails[each].replace(
                /(\r\n|\n|\r)/gm,
                ""
              );
            }
          });
          props.setData(labelDetails);
          setListIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (Object.keys(props.orderDetail).length) {
      setSelectedWO(props.orderDetail);
    }
  }, [props.orderDetail]);

  useEffect(() => {
    console.log("change in order ", selectedWO);
  }, [selectedWO]);

  useEffect(() => {
    fetch();
  }, []);

  return listIsLoading ? (
    <PageLoading noLogo={true} spinning={true} />
  ) : (
    <div
      className="absolute-center the-print-page print-page"
      style={{ marginTop: "50px" }}
    >
      <div
        className="width-100 relative padding-10"
        style={{
          border: "1px solid grey",
          borderRadius: "8px",
          marginTop: "5px",
          minHeight: "calc(100vh - 100px)",
        }}
      >
        <div
          className="no-print float-right center-hor"
          style={{ zIndex: "10", marginTop: "10px" }}
        >
          {/* <MdPrint onClick={()=>{window.print()}} className="icon large "/> */}
          {/* <MdCancel onClick={props.hidder} className="icon large white"/> */}
        </div>
        <div className="width-100 absolute-center">
          <img src={logo} style={{ width: "100px" }} />
        </div>
        <header style={{ fontSize: props.font + "px" }}>
          WORK ORDER # {selectedWO.serial_no}
        </header>

        <table
          className="table table-bordered table-striped"
          style={{ fontSize: props.font + "px" }}
        >
          <tbody className="print-wo">
            {selectedWO.type === "Funeral"
              ? [
                  <tr>
                    <td>ORDER TYPE</td>
                    <td>{selectedWO.type}</td>
                    <td>RECIPIENT</td>
                    <td>{selectedWO.late_person_name}</td>
                  </tr>,
                  <tr>
                    <td>FUNERAL LOCATION</td>
                    <td>
                      {JSON.parse(selectedWO.funeral_location || "{}").name}
                    </td>
                    <td>FUNERAL DIRECTOR</td>
                    <td>{JSON.parse(selectedWO.fd_code || "{}").code}</td>
                  </tr>,
                  <tr>
                    <td>FUNERAL DATE</td>
                    <td>
                      {moment(selectedWO.funeral_date, "DD/MM/YYYY").format(
                        "dddd"
                      ) +
                        " " +
                        moment(selectedWO.funeral_date, "DD/MM/YYYY").format(
                          "LL"
                        )}
                    </td>
                    <td>FUNERAL TIME</td>
                    <td>{selectedWO.funeral_time}</td>
                  </tr>,
                ]
              : [
                  <tr>
                    <td>ORDER TYPE</td>
                    <td>{selectedWO.type}</td>
                    <td>AUTORITY TO LEAVE</td>
                    <td>
                      {selectedWO.autority_to_leave ? (
                        <AiFillCheckCircle className="icon mid green" />
                      ) : (
                        <MdCancel className="icon mid red" />
                      )}
                    </td>
                  </tr>,
                  <tr>
                    <td>RECIPIENT</td>
                    <td>{selectedWO.recipient_name}</td>
                    <td>RECIPIENT PHONE</td>
                    <td>{selectedWO.recipient_phone}</td>
                  </tr>,
                  <tr>
                    <td>DELIVERY LOCATION</td>
                    <td>
                      {JSON.parse(selectedWO.delivery_location || "{}").name}
                    </td>
                    <td>DELIVERY AREA</td>
                    <td>{selectedWO.delivery_area}</td>
                  </tr>,
                  <tr>
                    <td>DELIVERY DATE</td>
                    <td>
                      {moment(props.date, "DD-MM-YYYY").format("dddd") +
                        " " +
                        moment(props.date, "DD-MM-YYYY").format("LL")}
                    </td>
                    <td>DELIVERY TIME</td>
                    <td>{selectedWO.time_of_delivery}</td>
                  </tr>,
                  <tr>
                    <td>COMPANY NAME</td>
                    <td>{selectedWO.company_name}</td>
                    <td></td>
                    <td></td>
                  </tr>,
                ]}
            <tr>
              <td>ORDER COMMENT</td>
              <td>{selectedWO.order_comment}</td>
              <td>CARD MESSAGE</td>
              <td>{selectedWO.card_message}</td>
            </tr>
            <tr>
              <td>ORDERED BY</td>
              <td>{selectedWO.ordered_by}</td>
              <td>PHONE</td>
              <td>{selectedWO.orderer_phone}</td>
            </tr>
            <tr>
              <td>PAYMENT METHOD</td>
              <td
                className={
                  selectedWO.payment_option === "**UNPAID**"
                    ? "red-text bold-1"
                    : ""
                }
              >
                {selectedWO.payment_option}
              </td>
              <td>INVOICE EMAIL</td>
              <td>{selectedWO.payment_email || "N/A"}</td>
            </tr>
          </tbody>
        </table>
        {/* <header style={{ fontSize: props.font + "px" }}>ITEMS Detail</header> */}
        <span className="width-100 padding-10">
          <div className="short-price font-medium center-hor">
            <span className="relative width-30 font-xmedium border-right">
              Total Floral Value
              <span className="float-right bold-1">
                $
                {Number(
                  (selectedWO.price_detail || {}).total_price || 0
                ).toFixed(2)}
              </span>
            </span>
            <span className="relative width-30 font-xmedium border-right">
              Delivery Fee
              <span className="float-right bold-1">
                $
                {Number(
                  (selectedWO.price_detail || {}).delivery_fee || 0
                ).toFixed(2)}
              </span>
            </span>
            {/* <div class="hor-line"></div> */}
            <span className="relative width-40 font-xmedium ">
              Total Order Value
              <span className="float-right bold-1">
                $
                {(
                  Number((selectedWO.price_detail || {}).delivery_fee || 0) +
                  Number((selectedWO.price_detail || {}).total_price || 0)
                ).toFixed(2)}
              </span>
            </span>
          </div>
        </span>
        <table
          className="table table-bordered table-striped"
          style={{ fontSize: props.font + "px", marginBottom: "50px" }}
        >
          <thead>
            <tr className="bold-1">
              <td>FLORIST</td>
              <td style={{ width: "100px" }}>DRIVER</td>
            </tr>
          </thead>
          <tbody>
            <tr className="bold-1">
              <td className="center-hor absolute-center relative">
                {selectedWO.type === "Funeral"
                  ? "***Casket Covers***"
                  : "***Products***"}
                <div
                  style={{ width: "100px" }}
                  className="center-hor float-right"
                >
                  <MdCheckBoxOutlineBlank className="icon mid" />
                  <MdCheckBoxOutlineBlank className="icon mid" />
                </div>
              </td>
              <td></td>
            </tr>
            {JSON.parse(
              (selectedWO.type === "Funeral"
                ? selectedWO.casket_cover_selected
                : selectedWO.products_selected) || "[]"
            ).map((each, index) => {
              return (
                <tr className="bold-1">
                  <td>
                    {each.amount} X {each.product_name.toUpperCase()} - $
                    {each.customPrice || each.price} -{" "}
                    {(each.size || "").toUpperCase()}
                    <ital
                      style={{
                        fontStyle: "italic",
                        fontSize: props.font * 1 + 2 + "px",
                        marginLeft: "20px",
                        color: "#B79855",
                      }}
                    >
                      {each.lidType ? (
                        <p className="margin-0 font-large margin-left-50 color-red bold-1">
                          Lid Type :
                          <span className="" style={{ fontWeight: "" }}>
                            {/* <Typewriter text={each.comment} /> */}

                            {each.lidType}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                      {each.comment ? (
                        <p className="margin-0 font-large margin-left-50">
                          COMMENT :
                          <span
                            className="color-red"
                            style={{ fontWeight: "" }}
                          >
                            {/* <Typewriter text={each.comment} /> */}
                            {each.comment}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                      {each.sash_message ? (
                        <p className="margin-0 font-large margin-left-50">
                          SASH :{" "}
                          <span
                            className="color-grey"
                            style={{ fontWeight: "" }}
                          >
                            {each.sash_message}
                          </span>
                        </p>
                      ) : (
                        ""
                      )}
                    </ital>
                  </td>
                  <td>
                    <MdCheckBoxOutlineBlank className="icon mid" />
                  </td>
                </tr>
              );
            })}
            {selectedWO.type === "Funeral" &&
              JSON.parse(selectedWO.tributes_selected || "[]").length > 0 && (
                <tr className="bold-1">
                  <td className="center-hor absolute-center relative">
                    ***Tributes***
                    <div
                      style={{ width: "100px" }}
                      className="center-hor float-right"
                    >
                      <MdCheckBoxOutlineBlank className="icon mid" />
                      <MdCheckBoxOutlineBlank className="icon mid" />
                    </div>
                  </td>
                  <td></td>
                </tr>
              )}
            {selectedWO.type === "Funeral" &&
              JSON.parse(selectedWO.tributes_selected || "[]").map(
                (each, index) => {
                  return (
                    <tr className="bold-1">
                      <td>
                        {each.amount} X {each.product_name.toUpperCase()} - $
                        {each.customPrice || each.price} -{" "}
                        {(each.size || "").toUpperCase()}
                        <ital
                          style={{
                            fontStyle: "italic",
                            marginLeft: "50px",
                            fontSize: props.font + 2 + "px",
                            color: "#B79855",
                          }}
                        >
                          {each.comment ? (
                            <p className="margin-0 font-large margin-left-50">
                              COMMENT :
                              <span
                                className="color-red"
                                style={{ fontWeight: "" }}
                              >
                                {/* <Typewriter text={each.comment} /> */}
                                {each.comment}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}
                          {each.sash_message ? (
                            <p className="margin-0 font-large margin-left-50">
                              SASH :{" "}
                              <span
                                className="color-grey"
                                style={{ fontWeight: "" }}
                              >
                                {each.sash_message}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}
                        </ital>
                      </td>
                      <td>
                        <MdCheckBoxOutlineBlank className="icon mid" />
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
        <div
          className="width-100 center-hor float-bottom"
          style={{ fontSize: props.font + "px", marginTop: "30px" }}
        >
          <span className="width-25 center-hor">
            <p className="width-100 bold-1 margin-0 padding-10">FINAL CHECK</p>
            <MdCheckBoxOutlineBlank className="icon mid" />
          </span>
          <span className="width-25 bold-1 padding-10">
            SIGHTED BY ________________________________
          </span>
          <span className="width-25 bold-1"></span>

          {/* <span className="width-25 text-right font-small bold-1" style={{marginRight: '30px', marginBottom:'-20px'}}>
                        </span> */}
        </div>
        <span
          className="float-bottom-risght"
          style={{ position: "absolute", bottom: "-20px", right: "40px" }}
        >
          {"Print Date : "}
          {moment(new Date()).format("LLL")}
        </span>
      </div>
    </div>
  );
}
