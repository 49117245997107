import SlipPrintLayout from "./components/SlipPrintLayout";
import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ExportToPDF = ({ children, fileName = "document.pdf" }) => {
  const contentRef = useRef();

  const handleExport = async () => {
    const content = contentRef.current;

    if (!content) return;

    // Render the content to a canvas
    // const canvas = await html2canvas(content, { scale: 2 });
    const canvas = await html2canvas(content, {
      scale: 2, // Improves image quality
      useCORS: true, // Enables cross-origin images to be loaded
      logging: true, // Enables debugging logs
    });

    const imageData = canvas.toDataURL("image/png");

    // Create a PDF
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(fileName);
  };

  return (
    <div>
      <div ref={contentRef}>{children}</div>
      <button onClick={handleExport} style={{ marginTop: "20px" }}>
        Export as PDF
      </button>
    </div>
  );
};

export default ExportToPDF;

export function Test() {
  return (
    <ExportToPDF fileName="slip.pdf">
      {/* <SlipPrintLayout
        details={{
          late_person_name: "",
          funeral_location: '{"name":"location"}',
          fd_code: '{"code":"FD"}',
          funeral_date: "11/11/2025",
        }}
      /> */}
      <h1>Hello There</h1>
    </ExportToPDF>
  );
}
